@import "colors";

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  // @include linear-gradient(to bottom, $background-top, $background-middle, $background-bottom);

  padding: 0;

  color: white;
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
}

#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
